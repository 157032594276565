<template>
  <p
    v-if="sessionStart && $store.state.auth.IsLoggedIn"
    class="clearfix mb-0"
  >
    <span
      class="float-md-left d-block d-md-inline-block mt-25"
    >
      Session-Time: {{ getHours }} hours {{ getMinutes }} mins
    </span>

    <span class="float-md-right d-none d-md-block">Last Login: {{ sessionStart | moment("MMMM Do, hh:mm A") }}
    </span>
  </p>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      sessionStart: 0,
      timeAlive: 0,
    }
  },
  computed: {
    getHours() {
      return this.sessionStart ? Math.trunc(this.timeAlive / (1000 * 60 * 60)) : 0
    },
    getMinutes() {
      // remainder of whole hours
      return this.sessionStart ? Math.trunc((this.timeAlive / (1000 * 60)) % 60) : 0
    },
    getSeconds() {
      // remainder of whole minutes
      return this.sessionStart ? Math.trunc((this.timeAlive / (1000)) % 60) : 0
    },
    getMilliseconds() {
      return this.sessionStart ? this.sessionStart.getTime() : 0
    },
  },
  async mounted() {
    setInterval(async () => {
      this.sessionStart = this.$store.state.auth.sessionStart
      this.timeAlive = Date.now() - this.getMilliseconds
    }, 1000)
  },
}
</script>
