export default [
    {
        title: 'Home',
        route: 'home',
        icon: 'HomeIcon',
    },
    {
        title: 'Cases',
        route: 'cases-view-list',
        icon: 'FolderIcon',
    },
]
